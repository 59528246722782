import React from "react";
import CardsItem from "@components/Cards/CardsItem";

function CardsList({cardList, title}) {
    return (
        <div className="cards-element">
            <div className="title">{title}</div>
            <ul className="cards-list">
                {[0, 1, 2].map((cardNumber, index) => (
                    <CardsItem cardNumber={cardNumber} key={index} cardList={cardList}/>
                ))}
            </ul>
        </div>)
}

export default CardsList