import React from "react";
import classNames from "classnames";
import {Prediction} from "@constants/Enums";
import {FormattedMessage} from "react-intl";

function LargeBoard({ odds, disabledAll, enabledBetAreas, selectedBoard, selectBoard}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 788 101"
             className="board-svg largeBoard">
            <defs>
                <linearGradient id="LargeBoardA" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#72bbef" stopOpacity=".502"/>
                    <stop offset="1" stopColor="#72bbef" stopOpacity=".702"/>
                </linearGradient>
                <linearGradient id="LargeBoardB" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#faa9ba" stopOpacity=".502"/>
                    <stop offset="1" stopColor="#faa9ba" stopOpacity=".702"/>
                </linearGradient>
                <linearGradient id="LargeBoardC" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#eaeaec" stopOpacity=".502"/>
                    <stop offset="1" stopColor="#eaeaec" stopOpacity=".702"/>
                </linearGradient>
            </defs>
            <g>
                <g>
                    <g>
                        <g className={classNames("back board-svg-item", {
                            disabled: enabledBetAreas.ABack || disabledAll || !odds[Prediction.ABack],
                            active: selectedBoard.board === Prediction.ABack
                        })} onClick={() => selectBoard(Prediction.ABack, odds[Prediction.ABack])}>
                            <g transform="translate(144 .5)" stroke="#72bbef" fill="url(#LargeBoardA)">
                                <path
                                    d="M24 0h92a4 4 0 0 1 4 4v92a4 4 0 0 1-4 4H24A24 24 0 0 1 0 76V24A24 24 0 0 1 24 0Z"
                                    stroke="none"/>
                                <path
                                    d="M24 .5h92a3.5 3.5 0 0 1 3.5 3.5v92a3.5 3.5 0 0 1-3.5 3.5H24A23.5 23.5 0 0 1 .5 76V24A23.5 23.5 0 0 1 24 .5Z"
                                    fill="none"/>
                            </g>
                            <text transform="translate(177 58.5)" fill="#fc0" fontSize="23" x="25" y="0"
                                  textAnchor="middle"
                                  fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                                {odds[Prediction.ABack] || ""}
                            </text>
                        </g>
                        <g className={classNames("lay board-svg-item", {
                            disabled: enabledBetAreas.ALay || disabledAll || !odds[Prediction.ALay],
                            active: selectedBoard.board === Prediction.ALay
                        })} onClick={() => selectBoard(Prediction.ALay, odds[Prediction.ALay])}>
                            <g transform="translate(268 .5)" stroke="#faa9ba" fill="url(#LargeBoardB)">
                                <rect width="120" height="100" rx="4" stroke="none"/>
                                <rect x=".5" y=".5" width="119" height="99" rx="3.5" fill="none"/>
                            </g>
                            <text x="10" y="0" textAnchor="middle" transform="translate(320 58.5)" fill="#fc0"
                                  fontSize="23"
                                  fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                                {odds[Prediction.ALay] || ""}
                            </text>
                        </g>
                        <g className={classNames("pair board-svg-item", {
                            disabled: enabledBetAreas.APair || disabledAll,
                            active: selectedBoard.board === Prediction.APair
                        })} onClick={() => selectBoard(Prediction.APair, "-")}>
                            <g transform="translate(0 24.5)" stroke="#eaeaec" fill="url(#LargeBoardC)">
                                <path
                                    d="M26 0h110.533A3.467 3.467 0 0 1 140 3.467v45.066A3.467 3.467 0 0 1 136.533 52H26A26 26 0 0 1 0 26 26 26 0 0 1 26 0Z"
                                    stroke="none"/>
                                <path
                                    d="M26 .5h110.475a3.025 3.025 0 0 1 3.025 3.025v44.95a3.025 3.025 0 0 1-3.025 3.025H26A25.5 25.5 0 0 1 .5 26 25.5 25.5 0 0 1 26 .5Z"
                                    fill="none"/>
                            </g>
                            <text transform="translate(22 55.5)" fill="#fff" fontSize="14"
                                  fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                                <tspan x="0" y="0"><FormattedMessage id="pair-plus"/> A</tspan>
                            </text>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g className={classNames("back board-svg-item", {
                            disabled: enabledBetAreas.BBack || disabledAll || !odds[Prediction.BBack],
                            active: selectedBoard.board === Prediction.BBack
                        })} onClick={() => selectBoard(Prediction.BBack, odds[Prediction.BBack])}>
                            <g stroke="#72bbef" fill="url(#LargeBoardA)" transform="translate(400 .5)">
                                <rect width="120" height="100" rx="4" stroke="none"/>
                                <rect x=".5" y=".5" width="119" height="99" rx="3.5" fill="none"/>
                            </g>
                            <text transform="translate(433 58.5)" fill="#fc0" fontSize="23" x="25" y="0"
                                  textAnchor="middle"
                                  fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                                {odds[Prediction.BBack] || ""}
                            </text>
                        </g>
                        <g className={classNames("lay board-svg-item", {
                            disabled: enabledBetAreas.BLay || disabledAll || !odds[Prediction.BLay],
                            active: selectedBoard.board === Prediction.BLay
                        })} onClick={() => selectBoard(Prediction.BLay, odds[Prediction.BLay])}>
                            <g stroke="#faa9ba" fill="url(#LargeBoardB)" transform="translate(524 .5)">
                                <path
                                    d="M4 0h92a24 24 0 0 1 24 24v52a24 24 0 0 1-24 24H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4Z"
                                    stroke="none"/>
                                <path
                                    d="M4 .5h92A23.5 23.5 0 0 1 119.5 24v52A23.5 23.5 0 0 1 96 99.5H4A3.5 3.5 0 0 1 .5 96V4A3.5 3.5 0 0 1 4 .5Z"
                                    fill="none"/>
                            </g>
                            <text transform="translate(576 58.5)" fill="#fc0" fontSize="23" x="10" y="0"
                                  textAnchor="middle"
                                  fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                                {odds[Prediction.BLay] || ""}
                            </text>
                        </g>
                        <g className={classNames("pair board-svg-item", {
                            disabled: enabledBetAreas.BPair || disabledAll,
                            active: selectedBoard.board === Prediction.BPair
                        })} onClick={() => selectBoard(Prediction.BPair, "-")}>
                            <g stroke="#eaeaec" fill="url(#LargeBoardC)" transform="translate(648 24.5)">
                                <path
                                    d="M3.467 0H114a26 26 0 0 1 26 26 26 26 0 0 1-26 26H3.467A3.467 3.467 0 0 1 0 48.533V3.467A3.467 3.467 0 0 1 3.467 0Z"
                                    stroke="none"/>
                                <path
                                    d="M3.525.5H114A25.5 25.5 0 0 1 139.5 26 25.5 25.5 0 0 1 114 51.5H3.525A3.025 3.025 0 0 1 .5 48.475V3.525A3.025 3.025 0 0 1 3.525.5Z"
                                    fill="none"/>
                            </g>
                            <text transform="translate(670 55.5)" fill="#fff" fontSize="14"
                                  fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                                <tspan x="0" y="0"><FormattedMessage id="pair-plus"/> B</tspan>
                            </text>
                        </g>
                    </g>
                </g>
                <path data-name="Line 293" fill="none" stroke="#878794" strokeLinecap="round" opacity=".5"
                      d="M394 .5v100"/>
            </g>
        </svg>
    )
}

export default LargeBoard