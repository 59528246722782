// export const DefaultLanguage = 'en-US';
export const DefaultLanguage = 'en';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];


export const BetsType = {
    1: "win",
    2: "lose",
    3: "refund",
    "win": 1,
    "lose": 2,
    "refund": 3
}

export const TimeTicker = {
    6: "yellow",
    3: "red",
    0: "white"
}

export const Prediction = {
    0: "ABack",
    1: "ALay",
    2: "APair",
    3: "BBack",
    4: "BLay",
    5: "BPair",
    "ABack": 0,
    "ALay": 1,
    "APair": 2,
    "BBack": 3,
    "BLay": 4,
    "BPair": 5
}

export const SocketUpdateType = {
    0: "Ping",
    1: "Error",
    2: "RoundStart",
    3: "Card",
    4: "Odd",
    5: "Timer",
    6: "Result",
    7: "Bet",
    8: "Pair"
}
export const CardsSuit = {
    1: "spades",
    2: "hearts",
    3: "clubs",
    4: "diamonds"
}

export const PlayerOddType = {
    0: "None",
    1: "PlayerA",
    2: "PlayerB",
    3: "Both",
    "None" : 0,
    "PlayerA": 1,
    "PlayerB": 2,
    "Both": 3
}

export const gameHistory = {
    0: "C",
    1: "A",
    2: "B",
    3: "T",
    "C": 0,
    "A": 1,
    "B": 2,
    "T": 3
}

export const SocketReadyState = {
    "CONNECTING": 0,
    "OPEN": 1,
    "CLOSING": 2,
    "CLOSED": 3
}