import {
    SET_CARDS,
    SET_GAME_INITIAL_DATA
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function cardsReducer(state = initialStore.cards, action) {
    let updateCards
    switch (action.type) {
        case SET_CARDS:
            updateCards = JSON.parse(JSON.stringify(initialStore.cards))
            action.card.forEach((card, index) => {
                if (index % 2 !==0) {
                    updateCards.playerB.push(card)
                } else {
                    updateCards.playerA.push(card)
                }
            })
            return updateCards;
        case SET_GAME_INITIAL_DATA:
            updateCards = JSON.parse(JSON.stringify(initialStore.cards))
            if (action.Cards) {
                action.Cards.Codes.forEach((card, index) => {
                    if (index % 2 !==0) {
                        updateCards.playerB.push(card)
                    } else {
                        updateCards.playerA.push(card)
                    }
                })
            }
            return updateCards;
        default:
            return state;
    }
}