import React from "react";
import cardBg from "@assets/img/cardBg.svg";
import openedCardBg from "@assets/img/opendedCardBg.svg";
import classNames from "classnames";

function CardsItem({cardNumber, show, cardList, ...restProps}) {
    
    return(
        <li className="cards-list-item card" {...restProps}>
            <div className={classNames("flip-card", {active: cardList[cardNumber]})}>
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={cardBg} alt={cardBg} />
                    </div>
                    <div className="flip-card-back">
                        <img src={openedCardBg} alt={openedCardBg} />
                        <i className={`cardIcon icon-${cardList[cardNumber]}`}/>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default CardsItem;