import React  from "react";
import {floatNumberWithSpaces} from "@services/numberFormatServices";
import {getDateDDMMYYUtc} from "@services/timestampService";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import {injectIntl} from "react-intl";
import {BetsType, Prediction} from "@constants/Enums";

function HistoryItem({item, onClick, intl, nextDay, getHistoryItemValue}) {
    return (
        <tr onClick={onClick} className={classNames({sum: nextDay})}>
            <td>
                <div className="info-box">
                    <div className="round-id"><FormattedMessage id="round_id" />: {item.roundId}</div>
                    <div className="date">
                        {getDateDDMMYYUtc(item.startTime, intl, "dd-mm-yy-time-am-pm")}
                    </div>
                </div>
            </td>
            <td>{floatNumberWithSpaces(item.bets.reduce((a, b) => a + b.stake, 0))}</td>
            <td>{floatNumberWithSpaces(item.bets.reduce((a, b) => a + (b.status === BetsType.win ? b.win : b.status === BetsType.refund ? 0 : -b.stake || 0), 0) || `0`, "0")}</td>
        </tr>)
}

export default injectIntl((HistoryItem))