import React from "react";
import classNames from "classnames";
import {Prediction} from "@constants/Enums";
import {FormattedMessage} from "react-intl";

function LargeBoard({odds, disabledAll, enabledBetAreas, selectedBoard, selectBoard}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344 81" className="board-svg smallBoard">
            <defs>
                <linearGradient id="SmallBoardA" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#faa9ba" stopOpacity=".502"/>
                    <stop offset="1" stopColor="#faa9ba" stopOpacity=".702"/>
                </linearGradient>
                <linearGradient id="SmallBoardB" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#72bbef" stopOpacity=".502"/>
                    <stop offset="1" stopColor="#72bbef" stopOpacity=".702"/>
                </linearGradient>
                <linearGradient id="SmallBoardC" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#eaeaec" stopOpacity=".502"/>
                    <stop offset="1" stopColor="#eaeaec" stopOpacity=".702"/>
                </linearGradient>
            </defs>
            <g>
                <g>
                    <g className={classNames("lay board-svg-item", {
                        disabled: enabledBetAreas.ALay || disabledAll || !odds[Prediction.ALay],
                        active: selectedBoard.board === Prediction.ALay
                    })} onClick={() => selectBoard(Prediction.ALay, odds[Prediction.ALay])}>
                        <g transform="translate(86 .5)" stroke="#faa9ba" fill="url(#SmallBoardA)">
                            <rect width="82" height="48" rx="2" stroke="none"/>
                            <rect x=".5" y=".5" width="81" height="47" rx="1.5" fill="none"/>
                        </g>
                        <text x="5" y="0" textAnchor="middle" transform="translate(122 29.5)" fill="#fc0" fontSize="14"
                              fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                            {odds[Prediction.ALay] || ""}
                        </text>
                    </g>
                    <g className={classNames("back board-svg-item", {
                        disabled: enabledBetAreas.ABack || disabledAll || !odds[Prediction.ABack],
                        active: selectedBoard.board === Prediction.ABack
                    })} onClick={() => selectBoard(Prediction.ABack, odds[Prediction.ABack])}>
                        <g transform="translate(0 .5)" stroke="#72bbef" fill="url(#SmallBoardB)">
                            <path d="M14 0h66a2 2 0 0 1 2 2v44a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V14A14 14 0 0 1 14 0Z"
                                  stroke="none"/>
                            <path
                                d="M14 .5h66A1.5 1.5 0 0 1 81.5 2v44a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 46V14A13.5 13.5 0 0 1 14 .5Z"
                                fill="none"/>
                        </g>
                        <text x="15" y="0" textAnchor="middle" transform="translate(25 29.5)" fill="#fc0" fontSize="14"
                              fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                            {odds[Prediction.ABack] || ""}
                        </text>
                    </g>
                    <g className={classNames("pair board-svg-item", {
                        disabled: enabledBetAreas.APair || disabledAll,
                        active: selectedBoard.board === Prediction.APair
                    })} onClick={() => selectBoard(Prediction.APair, "-")}>
                        <g transform="translate(0 52.5)" stroke="#eaeaec" fill="url(#SmallBoardC)">
                            <path d="M2 0h164a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2H14A14 14 0 0 1 0 14V2a2 2 0 0 1 2-2Z"
                                  stroke="none"/>
                            <path
                                d="M2 .5h164a1.5 1.5 0 0 1 1.5 1.5v24a1.5 1.5 0 0 1-1.5 1.5H14A13.5 13.5 0 0 1 .5 14V2A1.5 1.5 0 0 1 2 .5Z"
                                fill="none"/>
                        </g>
                        <text transform="translate(50 70.5)" fill="#fff" fontSize="10"
                              fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                            <tspan x="0" y="0"><FormattedMessage id="pair-plus"/> A</tspan>
                        </text>
                    </g>
                </g>
                <g>
                    <g className={classNames("lay board-svg-item", {
                        disabled: enabledBetAreas.BLay || disabledAll || !odds[Prediction.BLay],
                        active: selectedBoard.board === Prediction.BLay
                    })} onClick={() => selectBoard(Prediction.BLay, odds[Prediction.BLay])}>
                        <g transform="translate(262 .5)" stroke="#faa9ba" fill="url(#SmallBoardA)">
                            <path d="M2 0h66a14 14 0 0 1 14 14v32a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Z"
                                  stroke="none"/>
                            <path
                                d="M2 .5h66A13.5 13.5 0 0 1 81.5 14v32a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 46V2A1.5 1.5 0 0 1 2 .5Z"
                                fill="none"/>
                        </g>
                        <text fill="#fc0" fontSize="14" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800"
                              letterSpacing=".04em" x="302" y="30" textAnchor="middle">
                            {odds[Prediction.BLay] || ""}
                        </text>
                    </g>
                    <g className={classNames("back board-svg-item", {
                        disabled: enabledBetAreas.BBack || disabledAll || !odds[Prediction.BBack],
                        active: selectedBoard.board === Prediction.BBack
                    })} onClick={() => selectBoard(Prediction.BBack, odds[Prediction.BBack])}>
                        <g transform="translate(176 .5)" stroke="#72bbef" fill="url(#SmallBoardB)">
                            <rect width="82" height="48" rx="2" stroke="none"/>
                            <rect x=".5" y=".5" width="81" height="47" rx="1.5" fill="none"/>
                        </g>
                        <text fill="#fc0" fontSize="14" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800"
                              letterSpacing=".04em" x="218" y="30" textAnchor="middle">
                            {odds[Prediction.BBack] || ""}
                        </text>
                    </g>
                    <g className={classNames("pair board-svg-item", {
                        disabled: enabledBetAreas.BPair || disabledAll,
                        active: selectedBoard.board === Prediction.BPair
                    })} onClick={() => selectBoard(Prediction.BPair, "-")}>
                        <g transform="translate(176 52.5)" stroke="#eaeaec" fill="url(#SmallBoardC)">
                            <path d="M2 0h164a2 2 0 0 1 2 2v12a14 14 0 0 1-14 14H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Z"
                                  stroke="none"/>
                            <path
                                d="M2 .5h164a1.5 1.5 0 0 1 1.5 1.5v12A13.5 13.5 0 0 1 154 27.5H2A1.5 1.5 0 0 1 .5 26V2A1.5 1.5 0 0 1 2 .5Z"
                                fill="none"/>
                        </g>
                        <text transform="translate(226 70.5)" fill="#fff" fontSize="10"
                              fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing=".04em">
                            <tspan x="0" y="0"><FormattedMessage id="pair-plus"/> B</tspan>
                        </text>
                    </g>
                </g>
                <path stroke="#878794" strokeLinecap="round" opacity=".5" d="M172 .5v80"/>
            </g>
        </svg>
    )
}

export default LargeBoard