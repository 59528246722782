import {
    SET_GAME_CONDITION,
    SET_ODDS,
    SET_ROUND_START,
    SET_TIME_TICKER,
    SUB_ROUND_START
} from "@actions/actionTypes";
import {getRealOdd} from "@services/oddService";
import {PlayerOddType} from "@constants/Enums";
import {removeLocalStorage} from "@services/storageService";

export function subscribeRoundIdAction() {
    return dispatch => {
        const localstorageList = Object.entries(localStorage)

        dispatch({eventOn: SUB_ROUND_START,
            handle: (data) => {
                const odds = getRealOdd({Player: data.Odd?.Player, Lay: data.Odd?.Lay, Back: data.Odd?.Back});
                dispatch({type: SET_ODDS, odds, pair:  PlayerOddType.Both});
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Odd?.Start || 0, End: data.Odd?.End || 0}});
                dispatch({type: SET_ROUND_START, roundId : data.RoundId});
                dispatch({type: SET_GAME_CONDITION, condition : true});
                localstorageList.forEach(item => {
                    if (item[0].includes("Bets") && !item[0].includes(data.RoundId)) {
                        removeLocalStorage(item[0])
                    }
                })
            }
        });
    }
}