import {PlayerOddType, Prediction} from "@constants/Enums";

export function getRealOdd(oddsValue) {
    const {Player, Back, Lay} =  oddsValue;
    const odds = {}
    if (Player === PlayerOddType.Both) {
        odds[Prediction.ABack] = Back;
        odds[Prediction.ALay] = Lay;
        odds[Prediction.BBack] = Back;
        odds[Prediction.BLay] = Lay;
    } else if (Player === PlayerOddType.PlayerA){
        odds[Prediction.BBack] = undefined;
        odds[Prediction.BLay] = undefined;
        odds[Prediction.ABack] = Back;
        odds[Prediction.ALay] = Lay;
    } else if (Player === PlayerOddType.PlayerB){
        odds[Prediction.BBack] = Back;
        odds[Prediction.BLay] = Lay;
        odds[Prediction.ABack] = undefined;
        odds[Prediction.ALay] = undefined;
    }
    return odds;
}