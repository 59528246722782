import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {resetPlayingCards, subscribeCards} from "@actions/cardsAction";
import CardsList from "@components/Cards/CardsList";
import {FormattedMessage} from "react-intl";

function Cards({cards, gameCondition, resetPlayingCards, subscribeCards}) {
    const [playerACardList, setPlayerACardList] = useState([]);
    const [playerBCardList, setPlayerBCardList] = useState([]);

    useEffect(() => {
        setPlayerACardList(cards.playerA)
        setPlayerBCardList(cards.playerB)
    }, [cards])

    useEffect(() => {
        subscribeCards()
    }, [])

    useEffect(() => {
        if (!gameCondition) {
            resetPlayingCards()
        }
    }, [gameCondition])

    return (
        <div className="cards">
            <CardsList cardList={playerACardList} title={<FormattedMessage id="player_A"/>}/>
            <CardsList cardList={playerBCardList} title={<FormattedMessage id="player_B"/>}/>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        cards: state.cards,
        gameCondition: state.gameCondition,
    }
}

const mapDispatchToProps = {
    subscribeCards,
    resetPlayingCards,
}

export default connect(mapStateToProps, mapDispatchToProps)(Cards)