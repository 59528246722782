import React, {useEffect, useState} from "react";
import {gameHistory} from "@constants/Enums";
import {HistoryTotalIcon} from "@resource";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";

function GameHistoryHeader({history}) {
    const [total, setTotal] = useState(0)
    const [winPlayerA, setWinPlayerA] = useState(0)
    const [winPlayerB, setWinPlayerB] = useState(0)
    const [tie, setTie] = useState(0)
    useEffect(() => {
        let winPlayerA = 0,
            winPlayerB = 0,
            tie = 0;
        history.forEach(item => {
            if (item.Won === gameHistory.A) {
                winPlayerA++
            }

            if (item.Won === gameHistory.B) {
                winPlayerB++
            }

            if (item.Won === gameHistory.T) {
                tie++
            }
        })
        setTotal(history.length);
        setTie(tie);
        setWinPlayerA(winPlayerA);
        setWinPlayerB(winPlayerB);
    }, [history])
    return (
        <div className="game-history-header">
            <ul className="list">
                <li className="list-item">
                   <div className="icon"><HistoryTotalIcon /></div> {total}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.A} type="small"/> {winPlayerA}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.B} type="small"/> {winPlayerB}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.T} type="small"/> {tie}
                </li>
            </ul>
        </div>
    )
}

export default GameHistoryHeader