import React, {useEffect, useState} from "react";
import ToolTip from "@components/toolTip/toolTip";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";
import RoundInfoItem from "@components/history/RoundHistory/RoundInfoItem";
import CardList from "@components/history/RoundHistory/CardList";
import {FormattedMessage} from "react-intl";
import {CardsSuit, PlayerOddType} from "@constants/Enums";
import {ModalCloseIcon} from "@resource";

function GameHistoryLastResult({round, ...restProps}) {
    const [activeRound, setActiveRound] = useState(false)
    const [playerACards, setPlayerACards] = useState([])
    const [playerBCards, setPlayerBCards] = useState([])

    useEffect(() => {
        if (round.Cards) {
            let cardsA = [], cardsB = [];
            round.Cards.forEach((card, index) => {
                const cardItem = card.split(",")
                const cardString =  cardItem[0] + CardsSuit[cardItem[1]]
                if (index % 2 !==0) {
                    cardsB.push(cardString)
                } else {
                    cardsA.push(cardString)
                }
            })
            setPlayerBCards(cardsB)
            setPlayerACards(cardsA)
        }
    },[round])

    const trigger = (isActive) => {
        setActiveRound(isActive)
    }

    const getContent = () => {
        return (
            <div className="game-history-last-results-item">
                <div className="game-history-last-results-item-header">
                    <div className="title">
                        <FormattedMessage id="round_id" />: {round.RoundId}
                    </div>
                    <button className="circled-button small">
                        <ModalCloseIcon id={`GameHistoryLastResult${round.RoundId}`}/>
                    </button>
                </div>
                <div className="game-history-last-results-item-round-info">
                    <RoundInfoItem time={round.StartTime}
                                   timeTitle="game_start"/>
                    <RoundInfoItem time={round.EndTime}
                                   timeTitle="game_end"/>
                </div>
                <div className="game-history-last-results-item-round-cards">
                    <CardList player={<FormattedMessage id="player_a" />}
                              cards={playerACards}
                              winner={round.Won === PlayerOddType.PlayerA || round.Won === PlayerOddType.Both}/>

                    <CardList player={<FormattedMessage id="player_b" />}
                              cards={playerBCards}
                              winner={round.Won === PlayerOddType.PlayerB || round.Won === PlayerOddType.Both}/>
                </div>
            </div>
        )
    }
    return(
        <ToolTip title={getContent()} position={"top"} type="popup" triggerCallBack={trigger}{...restProps}>
            <GameHistoryIcon won={round.Won} type="large" round={round} active={activeRound}/>
        </ToolTip>
    )
}

export default GameHistoryLastResult